import {
	faFileAlt,
	faFileArchive,
	faFileAudio,
	faFileCode,
	faFileExcel,
	faFileImage,
	faFileLines,
	faFilePdf,
	faFilePowerpoint,
	faFileWord,
	faVideo,
} from "@fortawesome/free-solid-svg-icons";

export const getIcon = (mimeType) => {
	const iconMap = {
		"audio/mpeg": faFileAudio,
		"video/mp4": faVideo,
		"application/pdf": faFilePdf,
		"image/jpeg": faFileImage,
		"image/png": faFileImage,
		"application/msword": faFileWord,
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
			faFileWord,
		"application/vnd.openxmlformats-officedocument.wordprocessingml.template":
			faFileWord,
		"application/vnd.ms-excel": faFileExcel,
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
			faFileExcel,
		"application/vnd.openxmlformats-officedocument.spreadsheetml.template":
			faFileExcel,
		"application/vnd.ms-powerpoint": faFilePowerpoint,
		"application/vnd.openxmlformats-officedocument.presentationml.presentation":
			faFilePowerpoint,
		"application/zip": faFileArchive,
		"application/x-rar-compressed": faFileArchive,
		"application/x-zip-compressed": faFileArchive,
		"application/x-7z-compressed": faFileArchive,
		"application/json": faFileCode,
		"application/rtf": faFileCode,
		"application/octet-stream": faFileCode,
		"text/html": faFileCode,
		"application/javascript": faFileCode,
		"text/css": faFileCode,
		"application/x-python-code": faFileCode,
		"text/x-python": faFileCode,
		"application/x-sh": faFileCode,
		"application/x-csh": faFileCode,
		"text/x-c": faFileCode,
		"text/x-c++": faFileCode,
		"application/java-archive": faFileCode,
		"application/x-ruby": faFileCode,
		"text/x-ruby": faFileCode,
		"application/x-php": faFileCode,
		"text/x-php": faFileCode,
		"application/x-perl": faFileCode,
		"text/x-perl": faFileCode,
		"application/x-shellscript": faFileCode,
		"application/x-bash": faFileCode,
		"text/x-bash": faFileCode,
		"text/x-java-source": faFileCode,
		"text/x-csharp": faFileCode,
		"text/x-go": faFileCode,
		"text/x-rust": faFileCode,
		"text/x-swift": faFileCode,
		"application/x-tex": faFileCode,
		"text/x-tex": faFileCode,
		"application/xml": faFileCode,
		"text/xml": faFileCode,
		"application/x-sql": faFileCode,
		"text/x-sql": faFileCode,
		"application/x-markdown": faFileCode,
		"text/markdown": faFileCode,
		"application/x-lua": faFileCode,
		"text/x-lua": faFileCode,
		"application/x-yaml": faFileCode,
		"text/yaml": faFileCode,
		"application/x-toml": faFileCode,
		"text/x-toml": faFileCode,
		"text/plain": faFileAlt,
	};

	return iconMap[mimeType] || faFileLines;
};
