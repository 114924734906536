export const allowedTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "audio/mpeg",
    "video/mp4",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/plain",
    "application/zip",
    "application/x-rar-compressed",
    "application/x-zip-compressed",
    "application/octet-stream",
    "application/x-7z-compressed",
    "application/pdf",
    "application/rtf",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/json",
    "application/json",
    "text/html",
    "application/javascript",
    "text/css",
    "application/x-python-code",
    "text/x-python",
    "application/x-sh",
    "application/x-csh",
    "text/x-c",
    "text/x-c++",
    "application/java-archive",
    "application/x-ruby",
    "text/x-ruby",
    "application/x-php",
    "text/x-php",
    "application/x-perl",
    "text/x-perl",
    "application/x-shellscript",
    "application/x-bash",
    "text/x-bash",
    "text/x-java-source",
    "text/x-csharp",
    "text/x-go",
    "text/x-rust",
    "text/x-swift",
    "application/x-tex",
    "text/x-tex",
    "application/x-latex",
    "text/x-latex",
    "application/xml",
    "text/xml",
    "application/x-sql",
    "text/x-sql",
    "application/x-markdown",
    "text/markdown",
    "application/x-lua",
    "text/x-lua",
    "application/x-yaml",
    "text/yaml",
    "application/x-toml",
    "text/x-toml",
];